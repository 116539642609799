import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram, faFacebook, faFacebookSquare, faFacebookF, faLinkedinIn, faYoutube, faGratipay } from "@fortawesome/free-brands-svg-icons";
import {
    faBars,
    faBed,
    faMap,
    faWifi,
    faHotel,
    faUtensils,
    faBicycle,
    faCar,
    faTrain,
    faShoppingBag,
    faMapMarker,
    faStreetView,
    faMapPin,
    faInfo,
    faSuitcase,
    faBook,
    faMusic,
    faMapSigns,
    faCheckCircle,
    faStar,
    faShoppingBasket,
    faTree,
    faBinoculars,
    faHourglass,
    faHeart,
    faHeartbeat,
    faThumbsUp,
    faSun,
    faCalendar,
    faCheck,
    faUsers,
    faCircleInfo,
    faGlassMartini,
    faCircleQuestion,
    faNewspaper,
    faBell,
    faSmile,
    faTimes,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowUpRightFromSquare,
    faArrowDown,
    faQrcode,
    faCaretUp,
    faCaretDown,
    faChartPie,
    faComments,
    faCog,
    faFileAlt,
    faFileImage,
    faFilePdf,
    faPencilAlt,
    faArrowsAlt,
    faEdit,
    faCopy,
    faTrashAlt,
    faEye,
    faSignInAlt,
    faCommentDots,
    faSignOutAlt,
    faAngleDown,
    faAngleUp,
    faPlus,
    faHistory,
    faLink,
    faPhone,
    faEnvelope,
    faClock,
    faUser,
    faDollarSign,
    faChevronDown,
    faClone,
    faLaptop,
    faMobile,
    faFile,
    faMobileAlt,
    faMinus,
    faKey,
    faLock,
    faLockOpen,
    faAddressCard,
    faAmbulance,
    faAnchor,
    faArchway,
    faAt,
    faAtlas,
    faAward,
    faBaby,
    faBabyCarriage,
    faBalanceScale,
    faBan,
    faBandAid,
    faBarcode,
    faBasketballBall,
    faBath,
    faBeer,
    faBellSlash,
    faBiking,
    faBirthdayCake,
    faBlind,
    faBolt,
    faBomb,
    faBone,
    faBookOpen,
    faBookReader,
    faBookmark,
    faBowlingBall,
    faBox,
    faBoxOpen,
    faBrain,
    faBreadSlice,
    faBriefcase,
    faBriefcaseMedical,
    faBroom,
    faBuilding,
    faBullhorn,
    faBullseye,
    faBurn,
    faBus,
    faBusAlt,
    faBusinessTime,
    faCalculator,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarPlus,
    faCamera,
    faCampground,
    faCandyCane,
    faCapsules,
    faCarSide,
    faCarrot,
    faCartArrowDown,
    faCartPlus,
    faCashRegister,
    faCat,
    faCertificate,
    faChair,
    faChargingStation,
    faChartLine,
    faTimesCircle,
    faCheese,
    faChild,
    faChurch,
    faCity,
    faClinicMedical,
    faClipboardCheck,
    faClipboardList,
    faCloud,
    faCloudArrowUp,
    faCloudMoon,
    faCloudRain,
    faCloudShowersHeavy,
    faCloudSun,
    faCloudSunRain,
    faCocktail,
    faCoffee,
    faCoins,
    faComment,
    faCommentSlash,
    faCompass,
    faCompress,
    faConciergeBell,
    faCookie,
    faCouch,
    faCreditCard,
    faCrow,
    faCrown,
    faCut,
    faDesktop,
    faDice,
    faDog,
    faDolly,
    faDonate,
    faDoorClosed,
    faDoorOpen,
    faDove,
    faDownload,
    faDrum,
    faDrumstickBite,
    faDumbbell,
    faDumpster,
    faEgg,
    faEnvelopeOpen,
    faEraser,
    faEuroSign,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faEyeDropper,
    faEyeSlash,
    faFan,
    faFax,
    faFeather,
    faFemale,
    faFilm,
    faFingerprint,
    faFire,
    faFireExtinguisher,
    faFirstAid,
    faFish,
    faFlag,
    faFlask,
    faFootballBall,
    faFutbol,
    faGamepad,
    faGasPump,
    faGem,
    faGhost,
    faGift,
    faGifts,
    faGlassCheers,
    faGlassMartiniAlt,
    faGlassWhiskey,
    faGlasses,
    faGlobe,
    faGolfBall,
    faGraduationCap,
    faGrin,
    faGrinBeam,
    faGrinSquint,
    faGrinStars,
    faGuitar,
    faHamburger,
    faHammer,
    faHands,
    faHandsHelping,
    faHandshake,
    faHashtag,
    faHatCowboy,
    faHatWizard,
    faHeadphonesAlt,
    faHelicopter,
    faHighlighter,
    faHiking,
    faHippo,
    faHome,
    faHorse,
    faHorseHead,
    faHospital,
    faHotTub,
    faHotdog,
    faHourglassEnd,
    faHourglassHalf,
    faIceCream,
    faIcons,
    faIdBadge,
    faIgloo,
    faImage,
    faImages,
    faIndustry,
    faInfinity,
    faInfoCircle,
    faKeyboard,
    faLandmark,
    faLanguage,
    faLaugh,
    faLaughBeam,
    faLeaf,
    faLemon,
    faLifeRing,
    faLightbulb,
    faList,
    faLocationArrow,
    faLuggageCart,
    faMagic,
    faMailBulk,
    faMale,
    faMapMarked,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMarker,
    faMedal,
    faMicrophone,
    faMinusCircle,
    faMoneyBill,
    faMonument,
    faMoon,
    faMosque,
    faMotorcycle,
    faMountain,
    faMugHot,
    faOilCan,
    faPaintbrush,
    faPalette,
    faPaperPlane,
    faPaperclip,
    faParking,
    faPassport,
    faPaw,
    faPen,
    faPeopleCarry,
    faPercent,
    faPercentage,
    faPhoneAlt,
    faPhoneSlash,
    faPhoneSquare,
    faPhoneSquareAlt,
    faPhoneVolume,
    faPhotoVideo,
    faPiggyBank,
    faPills,
    faPizzaSlice,
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
    faPlug,
    faCirclePlus,
    faCircleNotch,
    faPodcast,
    faPortrait,
    faPoundSign,
    faPowerOff,
    faPrint,
    faPuzzlePiece,
    faQuestion,
    faRadiation,
    faRainbow,
    faRandom,
    faReceipt,
    faRecycle,
    faRedo,
    faRestroom,
    faRoad,
    faRobot,
    faRocket,
    faRoute,
    faRuler,
    faRunning,
    faRotateLeft,
    faSatelliteDish,
    faSave,
    faSchool,
    faScroll,
    faSdCard,
    faSearch,
    faSearchLocation,
    faSearchDollar,
    faSeedling,
    faShieldAlt,
    faShip,
    faShippingFast,
    faShoePrints,
    faShoppingCart,
    faShower,
    faShuttleVan,
    faSign,
    faSignLanguage,
    faSignal,
    faSignature,
    faSimCard,
    faSkating,
    faSkiing,
    faSkiingNordic,
    faSkullCrossbones,
    faSmoking,
    faSmokingBan,
    faSms,
    faSnowboarding,
    faSnowflake,
    faSnowman,
    faSocks,
    faSpa,
    faStore,
    faStoreAlt,
    faSubway,
    faSuitcaseRolling,
    faSwimmer,
    faSwimmingPool,
    faSync,
    faTableTennis,
    faTablet,
    faTabletAlt,
    faTag,
    faTags,
    faTaxi,
    faTemperatureHigh,
    faTheaterMasks,
    faTicketAlt,
    faToilet,
    faToiletPaper,
    faTools,
    faTooth,
    faTrafficLight,
    faTram,
    faTrash,
    faTrophy,
    faTruck,
    faTruckMoving,
    faTshirt,
    faTv,
    faUmbrella,
    faUmbrellaBeach,
    faUniversalAccess,
    faUniversity,
    faUnlock,
    faUserCheck,
    faUserCircle,
    faUserFriends,
    faUserTie,
    faVideo,
    faVolleyballBall,
    faWalking,
    faWallet,
    faWarehouse,
    faWater,
    faWeightHanging,
    faWheelchair,
    faWind,
    faWineBottle,
    faWineGlass,
    faWineGlassAlt,
    faWrench,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faBars,
    faBed,
    faMap,
    faWifi,
    faHotel,
    faUtensils,
    faBicycle,
    faCar,
    faTrain,
    faShoppingBag,
    faMapMarker,
    faStreetView,
    faMapPin,
    faInfo,
    faSuitcase,
    faBook,
    faMusic,
    faMapSigns,
    faCheckCircle,
    faStar,
    faShoppingBasket,
    faTree,
    faBinoculars,
    faHourglass,
    faHeart,
    faHeartbeat,
    faThumbsUp,
    faSun,
    faCalendar,
    faCheck,
    faUsers,
    faCircleInfo,
    faGlassMartini,
    faCircleQuestion,
    faNewspaper,
    faBell,
    faSmile,
    faTimes,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowUpRightFromSquare,
    faArrowDown,
    faQrcode,
    faCaretUp,
    faCaretDown,
    faChartPie,
    faComments,
    faCog,
    faFileAlt,
    faFileImage,
    faFilePdf,
    faPencilAlt,
    faArrowsAlt,
    faEdit,
    faCopy,
    faTrashAlt,
    faEye,
    faSignInAlt,
    faCommentDots,
    faSignOutAlt,
    faAngleDown,
    faAngleUp,
    faPlus,
    faHistory,
    faLink,
    faPhone,
    faEnvelope,
    faClock,
    faUser,
    faDollarSign,
    faChevronDown,
    faClone,
    faLaptop,
    faMobile,
    faFile,
    faMobileAlt,
    faMinus,
    faKey,
    faLock,
    faLockOpen,
    faAddressCard,
    faAmbulance,
    faAnchor,
    faArchway,
    faAt,
    faAtlas,
    faAward,
    faBaby,
    faBabyCarriage,
    faBalanceScale,
    faBan,
    faBandAid,
    faBarcode,
    faBasketballBall,
    faBath,
    faBeer,
    faBellSlash,
    faBiking,
    faBirthdayCake,
    faBlind,
    faBolt,
    faBomb,
    faBone,
    faBookOpen,
    faBookReader,
    faBookmark,
    faBowlingBall,
    faBox,
    faBoxOpen,
    faBrain,
    faBreadSlice,
    faBriefcase,
    faBriefcaseMedical,
    faBroom,
    faBuilding,
    faBullhorn,
    faBullseye,
    faBurn,
    faBus,
    faBusAlt,
    faBusinessTime,
    faCalculator,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarPlus,
    faCamera,
    faCampground,
    faCandyCane,
    faCapsules,
    faCarSide,
    faCarrot,
    faCartArrowDown,
    faCartPlus,
    faCashRegister,
    faCat,
    faCertificate,
    faChair,
    faChargingStation,
    faChartLine,
    faTimesCircle,
    faCheese,
    faChild,
    faChurch,
    faCity,
    faClinicMedical,
    faClipboardCheck,
    faClipboardList,
    faCloud,
    faCloudArrowUp,
    faCloudMoon,
    faCloudRain,
    faCloudShowersHeavy,
    faCloudSun,
    faCloudSunRain,
    faCocktail,
    faCoffee,
    faCoins,
    faComment,
    faCommentSlash,
    faCompass,
    faCompress,
    faConciergeBell,
    faCookie,
    faCouch,
    faCreditCard,
    faCrow,
    faCrown,
    faCut,
    faDesktop,
    faDice,
    faDog,
    faDolly,
    faDonate,
    faDoorClosed,
    faDoorOpen,
    faDove,
    faDownload,
    faDrum,
    faDrumstickBite,
    faDumbbell,
    faDumpster,
    faEgg,
    faEnvelopeOpen,
    faEraser,
    faEuroSign,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faEyeDropper,
    faEyeSlash,
    faFan,
    faFax,
    faFeather,
    faFemale,
    faFilm,
    faFingerprint,
    faFire,
    faFireExtinguisher,
    faFirstAid,
    faFish,
    faFlag,
    faFlask,
    faFootballBall,
    faFutbol,
    faGamepad,
    faGasPump,
    faGem,
    faGhost,
    faGift,
    faGifts,
    faGlassCheers,
    faGlassMartiniAlt,
    faGlassWhiskey,
    faGlasses,
    faGlobe,
    faGolfBall,
    faGraduationCap,
    faGrin,
    faGrinBeam,
    faGrinSquint,
    faGrinStars,
    faGuitar,
    faHamburger,
    faHammer,
    faHands,
    faHandsHelping,
    faHandshake,
    faHashtag,
    faHatCowboy,
    faHatWizard,
    faHeadphonesAlt,
    faHelicopter,
    faHighlighter,
    faHiking,
    faHippo,
    faHome,
    faHorse,
    faHorseHead,
    faHospital,
    faHotTub,
    faHotdog,
    faHourglassEnd,
    faHourglassHalf,
    faIceCream,
    faIcons,
    faIdBadge,
    faIgloo,
    faImage,
    faImages,
    faIndustry,
    faInfinity,
    faInfoCircle,
    faKeyboard,
    faLandmark,
    faLanguage,
    faLaugh,
    faLaughBeam,
    faLeaf,
    faLemon,
    faLifeRing,
    faLightbulb,
    faList,
    faLocationArrow,
    faLuggageCart,
    faMagic,
    faMailBulk,
    faMale,
    faMapMarked,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMarker,
    faMedal,
    faMicrophone,
    faMinusCircle,
    faMoneyBill,
    faMonument,
    faMoon,
    faMosque,
    faMotorcycle,
    faMountain,
    faMugHot,
    faOilCan,
    faPaintbrush,
    faPalette,
    faPaperPlane,
    faPaperclip,
    faParking,
    faPassport,
    faPaw,
    faPen,
    faPeopleCarry,
    faPercent,
    faPercentage,
    faPhoneAlt,
    faPhoneSlash,
    faPhoneSquare,
    faPhoneSquareAlt,
    faPhoneVolume,
    faPhotoVideo,
    faPiggyBank,
    faPills,
    faPizzaSlice,
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
    faPlug,
    faCirclePlus,
    faCircleNotch,
    faPodcast,
    faPortrait,
    faPoundSign,
    faPowerOff,
    faPrint,
    faPuzzlePiece,
    faQuestion,
    faRadiation,
    faRainbow,
    faRandom,
    faReceipt,
    faRecycle,
    faRedo,
    faRestroom,
    faRoad,
    faRobot,
    faRocket,
    faRoute,
    faRuler,
    faRunning,
    faRotateLeft,
    faSatelliteDish,
    faSave,
    faSchool,
    faScroll,
    faSdCard,
    faSearch,
    faSearchLocation,
    faSearchDollar,
    faSeedling,
    faShieldAlt,
    faShip,
    faShippingFast,
    faShoePrints,
    faShoppingCart,
    faShower,
    faShuttleVan,
    faSign,
    faSignLanguage,
    faSignal,
    faSignature,
    faSimCard,
    faSkating,
    faSkiing,
    faSkiingNordic,
    faSkullCrossbones,
    faSmoking,
    faSmokingBan,
    faSms,
    faSnowboarding,
    faSnowflake,
    faSnowman,
    faSocks,
    faSpa,
    faStore,
    faStoreAlt,
    faSubway,
    faSuitcaseRolling,
    faSwimmer,
    faSwimmingPool,
    faSync,
    faTableTennis,
    faTablet,
    faTabletAlt,
    faTag,
    faTags,
    faTaxi,
    faTemperatureHigh,
    faTheaterMasks,
    faTicketAlt,
    faToilet,
    faToiletPaper,
    faTools,
    faTooth,
    faTrafficLight,
    faTram,
    faTrash,
    faTrophy,
    faTruck,
    faTruckMoving,
    faTshirt,
    faTv,
    faUmbrella,
    faUmbrellaBeach,
    faUniversalAccess,
    faUniversity,
    faUnlock,
    faUserCheck,
    faUserCircle,
    faUserFriends,
    faUserTie,
    faVideo,
    faVolleyballBall,
    faWalking,
    faWallet,
    faWarehouse,
    faWater,
    faWeightHanging,
    faWheelchair,
    faWind,
    faWineBottle,
    faWineGlass,
    faWineGlassAlt,
    faWrench,
    faInstagram,
    faFacebook,
    faFacebookSquare,
    faFacebookF,
    faLinkedinIn,
    faYoutube,
    faGratipay
);