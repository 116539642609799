<template>
  <section class="global-toast" :class="type" @click="handleDeletionOfToast()">
    <!-- Icon -->
    <v-icon
      class="global-toast__icon"
      icon="check-circle"
      size="2x"
      :color="iconColor"
    ></v-icon>
    <!-- Content -->
    <div class="global-toast__body">
      <h3>{{ title }}</h3>
      <div>{{ content }}</div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    type: String,
    title: String,
    content: String,
    timestamp: Number,
  },
  computed: {
    iconColor() {
      let color = "#92C68C"; //green
      if (this.type == "copy") {
        color = "#62ACEB"; //blue
      } else if (this.type == "negative") {
        color = "#EB5757"; //red
      }
      return color;
    },
  },
  mounted() {
    setTimeout(() => {
      this.handleDeletionOfToast();
    }, 4500);
  },
  methods: {
    handleDeletionOfToast() {
      this.$toastEvents.splice(
        this.$toastEvents
          .map((x) => {
            return x.id;
          })
          .indexOf(this.timestamp),
        1
      );
    },
  },
};
</script>

<style scoped>
.global-toast {
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  width: 360px;
  margin-bottom: 10px;
  margin-right: 10px;
  min-height: 68px;
  padding: 10px 0;
}

.global-toast__body {
  margin-top: 4px;
  margin-left: 16px;
}
.global-toast__body h3 {
  margin-top: 5px;
}
.global-toast__icon {
  margin-top: 10px;
  margin-left: 20px;
}

.positive {
  border-left: 8px solid rgb(146, 198, 140);
}

.copy {
  border-left: 8px solid rgb(98, 171, 235);
}

.negative {
  border-left: 8px solid rgb(235, 98, 98);
}
</style>
